import nl2br from 'react-nl2br';
import styles from './SocialCard.bel.module.scss';

export interface ISocialCardProps {
  title: string;
  groupId: string;
  icon: JSX.Element;
  href?: string;
}

export function SocialCard({ title, icon, groupId, href }: ISocialCardProps) {
  return (
    <div className={styles.wrapper}>
      {icon}
      <div className={styles.info}>
        <div className={styles.title}>{nl2br(title)}</div>
        <div className={styles.group}>{groupId}</div>
        <a href={href} target="_blank" rel="noreferrer">
          <div className={styles.button}>Вступить в группу</div>
        </a>
      </div>
    </div>
  )
}
