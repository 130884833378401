import nl2br from 'react-nl2br';
import styles from './SocialCard.module.scss';

export interface ISocialCardProps {
  title: string;
  icon: JSX.Element;
  groupId: string;
  href?: string;
}

export function SocialCard({ title, icon, groupId, href }: ISocialCardProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.info}>
        <h2 className={styles.title}>{nl2br(title)}</h2>
        <p className={styles.group}>{groupId}</p>
        <a className={styles.button} href={href} target="_blank" rel="noreferrer">Вступить в группу</a>
      </div>
    </div>
  )
}
