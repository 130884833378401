import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { CarouselArrow } from '../Arrows/CarouselArrow';
import styles from './ButtonGroup.module.scss';

export interface ICustomButtonGroupProps {
  next?: () => void;
  previous?: () => void;
  goToSlide?: () => void;
  carouselState?: {
    currentSlide: number;
  }
  className?: string;
  buttonClassName?: string;
}

export function CustomButtonGroup({
  next, previous, className, buttonClassName
}: ICustomButtonGroupProps): ReactElement {
  return (
    <div className={classNames(styles.buttonGroup, "carousel-button-group", className && className)}>
      <CarouselArrow className={buttonClassName} direction='left' onClick={() => previous()}/>
      <CarouselArrow className={buttonClassName} direction='right' onClick={() => next()}/>
    </div>
  );
}
