import {
  BigVkIcon,
  BigVkIconMobile,
  BigVkIconV2,
  BigVkIconMobileV2,
  BigVkIconSochi,
  BigVkIconMobileSochi,
} from '@kursk/components/ui/icons';
import { useOrientations } from "@common/hooks/useOrientation";
import { getComponentVersion } from "@kursk/utils/componentsTemplates";

const componentsHash = {
  default: BigVkIcon,
  sochi: BigVkIconSochi,
  v2: BigVkIconV2,
}

const componentsMobileHash = {
  default: BigVkIconMobile,
  sochi: BigVkIconMobileSochi,
  v2: BigVkIconMobileV2,
}

export function VkIconDefault() {
  const orientation = useOrientations();

  const componentVersion = getComponentVersion({ componentType: 'socialCardIconVk' });
  const Component = componentsHash[componentVersion] || componentsHash.default;
  const ComponentMobile = componentsMobileHash[componentVersion] || componentsMobileHash.default;

  return (
    <>
      {orientation === 'desktop' ? (
        <Component />
      ) : (
        <ComponentMobile />
      )}
    </>
  )
}
