import {
  BigTelegramIcon,
  BigTelegramIconMobile,
  BigTelegramIconV2,
  BigTelegramIconMobileV2,
  BigTelegramIconSochi,
  BigTelegramIconMobileSochi,
} from '@kursk/components/ui/icons';
import { useOrientations } from "@common/hooks/useOrientation";
import { getComponentVersion } from "@kursk/utils/componentsTemplates";

const componentsHash = {
  default: BigTelegramIcon,
  sochi: BigTelegramIconSochi,
  v2: BigTelegramIconV2,
}

const componentsMobileHash = {
  default: BigTelegramIconMobile,
  sochi: BigTelegramIconMobileSochi,
  v2: BigTelegramIconMobileV2,
}

export function TgIconDefault() {
  const orientation = useOrientations();

  const componentVersion = getComponentVersion({ componentType: 'socialCardIconTg' });
  const Component = componentsHash[componentVersion] || componentsHash.default;
  const ComponentMobile = componentsMobileHash[componentVersion] || componentsMobileHash.default;

  return (
    <>
      {orientation === 'desktop' ? (
        <Component />
      ) : (
        <ComponentMobile />
      )}
    </>
  )
}
