import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { ArrowDownIcon } from '../../icons';
import styles from './CarouselArrow.module.scss';
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface ICarouselArrowProps {
  onClick?: () => void;
  direction: 'left' | 'right' | 'right-recommendation';
  className?: string;
}

export function CarouselArrow({ onClick = null, direction, className }: ICarouselArrowProps): ReactElement {
  return (
    <button
      className={classNames(styles.button, {
        [styles.left]: direction === 'left',
        [styles.right]: direction === 'right',
        [styles.right__recommendation]: direction === 'right-recommendation'
      }, className)}
      onClick={onClick}
    >
      {themeValue({
        bel: <BelDownArrowIcon/>,
        default: <ArrowDownIcon width={20} height={8}/>
      })}
    </button>
  );
}
