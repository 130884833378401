import React, { ReactElement } from 'react';
import { PushkinCardIcon } from '@kursk/components/ui/icons';
import styles from "./PushkinIconForCard.module.scss";

export function PushkinIconForCard(): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconBg} />
      <PushkinCardIcon />
    </div>
  );
}
