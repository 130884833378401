import classNames from 'classnames';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { IEvent } from '@common/redux/types/event';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import { PushkinIconForCard } from '@kursk/components/ui/PushkinIconOnCard/PushkinIconForCard';
import style from './EventCard.module.scss';
import { isTicketsInfoAvailable } from '@common/utils/tickets';
import _ from 'lodash';
import { getPriceTitle } from '@kursk/utils/getPriceTitle';

interface IEventCardProps {
  event: IEvent;
  type: 'small' | 'medium' | 'large';
  customStyle?: {
    readonly [key: string]: string;
  }
  theme?: 'white';
}

const isEipskPricesExists = (event: IEvent) =>
  !!event.eipskPrices && (!_.isNull(event.eipskPrices.min) || !_.isNull(event.eipskPrices.max));

export function EventCard({ event, type, customStyle, theme }: IEventCardProps): ReactElement {
  const styles = customStyle || style;
  const [isHover, setIsHover] = useState(false);

  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 0px)',
      width: type === 'small' ? 270 : type === 'medium' ? 580 : 1200,
      height: 241,
      cropMode: type === 'small' ? 'noCrop' : 'fromTop',
    }
  ];
  const prepareDate = (): string => {
    const format = 'DD MMMM YYYY';
    const start = moment(event?.actualSeances?.nearest?.periodStart);
    const end = moment(event?.actualSeances?.nearest?.periodEnd);

    const isSeance = event.type === 'seances';

    if (isSeance) {
      return start.format(format);
    } else {
      const now = moment();
      const currentStart = start.valueOf() < now.valueOf() ? now : start;
      const startFormatted = currentStart.format(format);
      const endFormatted = end.format(format);

      return startFormatted == endFormatted ? endFormatted : `${startFormatted} - ${endFormatted}`;
    }

  };

  const getPriceInfo = (): string | JSX.Element => {
    if (event.isFree) {
      return 'Бесплатно';
    }

    const { all } = event?.actualSeances || {};
    const { schedules } = event;
    const { eipskPrices: { max, min } = {} } = event;
    const now = moment();

    const allShedules = all?.length ? all : _.filter(schedules, ({ end }) => moment(end).isAfter(now));

    const availableTicketsInfos = _(allShedules).map('ticketsInfo')
      .filter((ticketsInfo) => isTicketsInfoAvailable(ticketsInfo))
      .value();

    const maxPrice = _(availableTicketsInfos).map('maxPrice').max();
    const minPrice = _(availableTicketsInfos).map('minPrice').min();

    if (!_.isUndefined(minPrice) && !_.isUndefined(maxPrice)) {
      return getPriceTitle(minPrice, maxPrice);
    }


    if (isEipskPricesExists(event)) {
      return getPriceTitle(min * 100, max * 100);
    }

    return '';
  }

  const priceInfo = getPriceInfo();

  const isPushkin = event.source?.meta?.pushkin;

  return (
    <Link href={`/events/${event._id}/${event.sysName}`}>

      <a>
        <div
          className={
            classNames(
              styles.wrapper,
              type === 'medium' && styles.mediumType,
              type === 'large' && styles.largeType,
              theme === 'white' && styles.white
            )}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className={styles.badge}>{event.category?.name ? event?.category?.name : 'трансляция'}</div>
          <Picture className={styles.image} srcSet={imgSrcSet} image={event.image}/>
          <div className={
            classNames(
              styles.info,
              type === 'medium' && styles.mediumTypeInfo,
              type === 'large' && styles.largeTypeInfo
            )
          }>
            {isPushkin && <PushkinIconForCard />}
            <p className={styles.date}>{prepareDate()}</p>
            <LinesEllipsis
              text={event.name}
              maxLine={isHover ? 4 : 2}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            {priceInfo && (
              <p className={styles.price}>{priceInfo}</p>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
