import { regionContent } from '@kursk/content/regionContent';
import { SocialCard } from './SocialCard/SocialCard';
import { SocialCard as SocialCardBel } from './SocialCard.bel/SocialCard.bel';
import styles from './Socials.module.scss';
import { themeValue } from "@kursk/themes";
import { VkIconDefault } from "./SocialCard/VkIcon";
import { TgIconDefault } from "./SocialCard/TgIcon";
import { VkIcon } from "@kursk/components/pages/Home/Socials/SocialCard.bel/VkIcon";
import { TgIcon } from "@kursk/components/pages/Home/Socials/SocialCard.bel/TgIcon";

const getGroupId = (link) => {
  const id = link.split("/").pop();
  return `@${id}`;
};

const getGroupUrl = (url) => {
  const urlObj = new URL(url);
  return urlObj.host + urlObj.pathname;
}

export function Socials() {

  return regionContent.socials.vk && regionContent.socials.telegram ? (
    <div className={styles.wrapper}>
      {themeValue({
        bel: <SocialCardBel
          title={regionContent.socialTitles.vk}
          groupId={getGroupUrl(regionContent.socials.vk)}
          icon={<VkIcon />}
          href={regionContent.socials.vk}
        />,
        default: <SocialCard
          title={regionContent.socialTitles.vk}
          groupId={getGroupId(regionContent.socials.vk)}
          icon={<VkIconDefault />}
          href={regionContent.socials.vk}
        />
      })}
      {themeValue({
        bel: <SocialCardBel
          title={regionContent.socialTitles.telegram}
          groupId={getGroupUrl(regionContent.socials.telegram)}
          icon={<TgIcon />}
          href={regionContent.socials.telegram}
        />,
        default: <SocialCard
          title={regionContent.socialTitles.telegram}
          groupId={getGroupId(regionContent.socials.telegram)}
          icon={<TgIconDefault />}
          href={regionContent.socials.telegram}
        />
      })}
    </div>
  ) : null;
}
