import styles from './SocialCard.bel.module.scss';
import { useOrientations } from "@common/hooks/useOrientation";

export function VkIcon() {
  let orientation = useOrientations();
  return (
    <>
      {orientation === 'desktop' ? (
        <div className={styles.icon}>
          <div className={styles.block1}>
            <img src="/images/socialImages/vk.svg"/>
          </div>
          <div className={styles.block2}>
            <img src="/images/socialImages/image439.png"/>
          </div>
          <div className={styles.block3}>
            <img src="/images/socialImages/image441.png"/>
          </div>
          <div className={styles.block4}>
            <img src="/images/socialImages/image440.png"/>
          </div>
        </div>
      ) : (
        <div className={styles.icon}>
          <div className={styles.block1}>
            <img src="/images/socialImages/vk.svg"/>
          </div>
          <div className={styles.block2}>
            <img src="/images/socialImages/image439.png"/>
          </div>
          <div className={styles.block3}>
            <img src="/images/socialImages/image441.png"/>
          </div>
          <div className={styles.block4}>
            <img src="/images/socialImages/image440.png"/>
          </div>
          <div className={styles.block5}>
            <img src="/images/socialImages/image458.png"/>
          </div>
          <div className={styles.block6}>
            <img src="/images/socialImages/image454.png"/>
          </div>
          <div className={styles.block7}>
            <img src="/images/socialImages/image457.png"/>
          </div>
        </div>
      )}
    </>
  )
}
