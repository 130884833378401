import styles from './SocialCard.bel.module.scss';
import { useOrientations } from "@common/hooks/useOrientation";

export function TgIcon() {
  let orientation = useOrientations();

  return (
    <>
      {orientation === 'desktop' ? (
        <div className={styles.icon}>
          <div className={styles.block1}>
            <img src="/images/socialImages/tg.svg"/>
          </div>
          <div className={styles.block2}>
            <img src="/images/socialImages/image444.png"/>
          </div>
          <div className={styles.block3}>
            <img src="/images/socialImages/image442.png"/>
          </div>
          <div className={styles.block4}>
            <img src="/images/socialImages/image443.png"/>
          </div>
        </div>
      ) : (
        <div className={styles.icon}>
          <div className={styles.block1}>
            <img src="/images/socialImages/tg.svg"/>
          </div>
          <div className={styles.block2}>
            <img src="/images/socialImages/image444.png"/>
          </div>
          <div className={styles.block3}>
            <img src="/images/socialImages/image442.png"/>
          </div>
          <div className={styles.block4}>
            <img src="/images/socialImages/image443.png"/>
          </div>
          <div className={styles.block5}>
            <img src="/images/socialImages/image438.png"/>
          </div>
          <div className={styles.block6}>
            <img src="/images/socialImages/image445.png"/>
          </div>
          <div className={styles.block7}>
            <img src="/images/socialImages/image451.png"/>
          </div>
        </div>
      )}
    </>
  )
}
